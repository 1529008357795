import { Dropdown } from "bootstrap";
const ESCAPE_KEY = "Escape";
const ARROW_UP_KEY = "ArrowUp";
const ARROW_DOWN_KEY = "ArrowDown";
const ARROW_LEFT_KEY = "ArrowLeft";
const ARROW_RIGHT_KEY = "ArrowRight";
const initDesktopMenu = () => {
  const { Theme } = window;
  const { desktopMenu: desktopMenuMap } = Theme.selectors;
  const dropdownToggles = document.querySelectorAll(desktopMenuMap.dropdownToggles);
  dropdownToggles.forEach((dropdownToggleElement) => {
    const dropdown = new Dropdown(dropdownToggleElement, {
      popperConfig: () => ({ placement: "auto-end" })
    });
    function greeter(fn) {
      fn("Hello, World");
    }
    const dropdownElement = dropdownToggleElement.parentElement;
    dropdownElement == null ? void 0 : dropdownElement.addEventListener("mouseover", () => {
      dropdown.show();
      dropdownToggleElement.blur();
    });
    dropdownElement == null ? void 0 : dropdownElement.addEventListener("mouseout", () => {
      dropdown.hide();
    });
    dropdownToggleElement.addEventListener("keydown", (event) => {
      if (event.key === ARROW_LEFT_KEY || event.key === ARROW_RIGHT_KEY) {
        dropdown.show();
      }
      if (event.key === ESCAPE_KEY) {
        dropdown.hide();
      }
    });
  });
  const mainMenuLinks = document.querySelectorAll(desktopMenuMap.dropdownItemAnchor(0));
  const menuItems = ["Huiles", "Cosm\xE9tique", "Th\xE9s et Tisanes", "G\xE9lules", "Oral Strips", "E-liquides et Puffs"];
  mainMenuLinks.forEach((mainMenuLinkElement) => {
    const menuContainerElement = mainMenuLinkElement.nextElementSibling;
    menuItems.forEach((item) => {
      if (mainMenuLinkElement.innerText.indexOf(item) >= 0) {
        let menuItemText = mainMenuLinkElement.innerText.split(item);
        mainMenuLinkElement.innerHTML = item + `<br><small>${menuItemText[1]}</small>`;
      }
    });
    mainMenuLinkElement.addEventListener("keydown", (event) => {
      if (event.key === ARROW_UP_KEY || event.key === ARROW_DOWN_KEY) {
        menuContainerElement == null ? void 0 : menuContainerElement.classList.add("focusing");
        const menuContainerLinks = menuContainerElement == null ? void 0 : menuContainerElement.querySelectorAll(desktopMenuMap.dropdownItemAnchor(1));
        if (menuContainerLinks && menuContainerLinks.length) {
          const targetIndex = event.key === ARROW_DOWN_KEY ? 0 : menuContainerLinks.length - 1;
          menuContainerLinks.item(targetIndex).focus();
        }
        menuContainerElement == null ? void 0 : menuContainerElement.classList.remove("focusing");
        event.stopPropagation();
        event.preventDefault();
      }
    });
    menuContainerElement == null ? void 0 : menuContainerElement.addEventListener("keydown", (event) => {
      if (event.key === ESCAPE_KEY) {
        mainMenuLinkElement.focus();
      }
    });
  });
};
export default initDesktopMenu;
